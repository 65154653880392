import * as React from 'react'
import useSiteMetadata from '../utilities/get-site-metadata'

const SocialShareButtons = (props) => {
  const { siteUrl } = useSiteMetadata()

  const currentURL = siteUrl + props.location
  const currentURLEncoded = encodeURI(currentURL)
  const encodedSubject = encodeURIComponent('Check out this recipe from Meati™')
  const encodedBody = encodeURIComponent(`Recipe: ${props.title} - ${currentURL}`)
  const encodedTitle = encodeURIComponent(`Recipe: ${props.title}`)

  const icons = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${currentURLEncoded}`,
      network: 'Facebook',
      path: '<path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />',
      viewBox: '0 0 320 512',
    },
    {
      url: `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${currentURLEncoded}`,
      network: 'Twitter',
      path: '<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />',
      viewBox: '0 0 512 512',
    },
    {
      url: `mailto:?subject=${encodedSubject}&body=${encodedBody}`,
      network: 'Email',
      path: '<path d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z" />',
      viewBox: '0 0 512 512',
    },
  ]

  return (
    <div className="social-share-buttons">
      <h5 className="mb-12">Share recipe:</h5>
      <div className="social-share-icons d-flex align-items-center justify-content-start">
        {
          icons.map((node, index) => (
            <a
              key={`share-icon-${index}`}
              href={node.url}
              className={`ad-el-tracked me-12 p-12 d-flex align-items-center justify-content-center share-icon-${node.network}`}
              rel="noopener noreferrer"
              target="_blank"
              data-ad-element-type="social share button"
              data-ad-text={`icon-${node.network}`}
              data-ad-button-location="body"
              data-ad-button-id="version 1"
            >
              <span className="visually-hidden-focusable">
                Share Meati™ via
                {node.network}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox={node.viewBox} dangerouslySetInnerHTML={{ __html: node.path }} />
            </a>
          ))
        }
      </div>
    </div>
  )
}

export default SocialShareButtons
