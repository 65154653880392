import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import ImageCorner from '../images/image-corner-recipe.svg'
import useSiteMetadata from '../components/utilities/get-site-metadata'

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import '../css/@wordpress/block-library/build-style/style.css'
import '../css/@wordpress/block-library/build-style/theme.css'

import Layout from '../components/layout'
import Seo from '../components/seo'
import MainCTA from '../components/sections/main-cta'
import SocialShareButtons from '../components/common/social-share-buttons'
import RecipeCarouselBlocks from '../components/sections/recipe-carousel-blocks'

const RecipeTemplate = ({ data: { post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || '',
  }
  const { siteUrl } = useSiteMetadata()
  const {
    title, slug, recipes, excerpt,
  } = post

  const madeWithString = post.recipes.madeWith2.map((node, index) => node.title).join(', ')

  const imagePath = siteUrl + post.featuredImage.node.publicUrl
  // console.log(post.featuredImage.node.publicUrl);

  const productobj = {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    author: 'Meati™',
    name: `${title}`,
    image: [
      `${imagePath}`,
    ],
    description: `${excerpt}`,
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    gsap.to(window, { duration: 0.35, scrollTo: 0 })

    const mm = gsap.matchMedia()
    mm.add('(min-width: 576px)', () => {
      const pinTl = gsap.timeline({
        scrollTrigger: {
          trigger: '#pageWrap',
          pin: '#recipeImageColumn',
          start: 'top top',
          endTrigger: '#singleRecipe',
          end: 'bottom bottom+=100px',
          pinSpacing: false,
        },
      })
    })

    ScrollTrigger.matchMedia({
      '(min-width: 576px)': function () {

      },
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  const meta = [
    {
      name: 'og:image',
      content: imagePath,
    },
    {
      name: 'twitter:image',
      content: imagePath,
    },
  ]

  const regex = /(<([^>]+)>)/gi
  const newExcerpt = excerpt.replace(regex, '')

  console.log(recipes)

  return (
    <Layout>
      <Seo meta={meta} title={`Meati ${post.title} Recipe`} description={excerpt} productobj={productobj} />

      <section id="singleRecipe">
        <Container className="py-2">
          <Row className="align-items-start">
            <Col sm="5" md="6" lg="7" id="recipeImageColumn">
              <div className="position-relative">
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                  className="recipe-featured-image"
                />
                <img src={ImageCorner} alt="imagecover" className="recipe-featured-image-overlay" />
              </div>
            </Col>
            <Col sm="7" md="6" lg="5">
              <h1 className="fw-bolder mt-1 mb-1 text-primary">{title}</h1>
              <h2 className="mb-1">{newExcerpt}</h2>

              <div className="recipe-meta d-xl-flex align-items-center mb-2">
                { recipes.serves
                  && (
                  <div className="serves me-xl-1 pe-xl-1">
                    <span className="text-uppercase">Serves:</span>
                    {' '}
                    {recipes.serves}
                  </div>
                  )}
                {madeWithString
                  && (
                  <div className="made-with">
                    <span className="text-uppercase">Made with:</span>
                    {' '}
                    {madeWithString}
                  </div>
                  )}
              </div>
              { (recipes.prepTime || recipes.CookTime)
                && (
                <div className="d-inline-flex gap-2  mb-2 border border-warning rounded-5 p-1 recipe-time">
                  { recipes.prepTime
                    && (
                    <div className="d-flex align-items-center flex-column">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="mb-14 d-block"><path d="M566.28 88.57c12.96-12.5 12.96-32.76 0-45.25L531.07 9.37c-12.96-12.5-33.98-12.5-46.94 0L319.99 160 4.76 464.14c-8.25 7.96-5.38 22.16 5.53 25.69C53.72 503.86 102.37 512 150.51 512c75.83 0 150.42-20.19 201.49-69.35l104.4-100.04c12.95-12.41 13.17-33.05.49-45.73L448 288v-80L566.28 88.57zM496 64c8.84 0 16 7.16 16 16s-7.16 16-16 16-16-7.16-16-16 7.16-16 16-16zM318.71 408.07C281.24 444.14 221.5 464 150.51 464c-23.16 0-46.79-2.1-70.07-6.17L319.4 227.28l91.99 91.99-92.68 88.8zM432 160c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.17 16-16 16z" /></svg>
                      <small className="text-uppercase text-muted">
                        Prep time
                      </small>
                      <div className="fw-bold fs-5">
                        {recipes.prepTime}
                        {' '}
                        min
                      </div>
                    </div>
                    )}
                  { recipes.cookTime
                    && (
                    <div className="d-flex align-items-center flex-column">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="mb-14 d-block"><path d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 224 64 224v128a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352s64-182.26 64-224a96 96 0 0 0-96-96zM112 464v-80h288v80zm290.74-128h-29.55L384 201.25a8 8 0 0 0-7.33-8.61l-16-1.28h-.65a8 8 0 0 0-8 7.37l-11 137.3h-69.68V200a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v136h-68.42l-11-137.3a8 8 0 0 0-8-7.37h-.65l-16 1.28a8 8 0 0 0-7.33 8.61L138.81 336h-29.55C80 252.78 48.46 149.34 48 128a47.57 47.57 0 0 1 76.72-38l38.52 29.22 28.94-38.73C207.6 59.84 230.86 48 256 48s48.4 11.84 63.82 32.47l28.94 38.73L387.28 90A47.57 47.57 0 0 1 464 127.92c-.46 21.42-32 124.86-61.26 208.08z" /></svg>
                      <small className="text-uppercase text-muted">Cook time</small>
                      <div className="fw-bold fs-5">
                        {recipes.cookTime}
                        {' '}
                        min
                      </div>
                    </div>
                    )}
                  { (recipes.prepTime && recipes.cookTime)
                    && (
                    <div className="d-flex align-items-center flex-column">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="mb-14 d-block"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" /></svg>
                      <small className="text-uppercase text-muted">Total time</small>
                      <div className="fw-bold fs-5">
                        {recipes.prepTime + recipes.cookTime}
                        {' '}
                        min
                      </div>
                    </div>
                    )}
                </div>
                )}

              <SocialShareButtons title={title} location={`/recipes/${slug}`} />
              {parse(post.content)}
            </Col>
          </Row>
        </Container>
      </section>
      <RecipeCarouselBlocks handle="all" />
      <MainCTA />
    </Layout>
  )
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpRecipe(id: { eq: $id }) {
      id
      excerpt
      content
      slug
      title
      date(formatString: "MMMM DD, YYYY")
      recipes {
        cookTime
        prepTime
        madeWith
        madeWith2 {
          ... on WpCut {
            title
          }
        }
        serves
        shareEnabled
      }
      types {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          altText
          publicUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                width: 1492
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
                aspectRatio: 1
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
